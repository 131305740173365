/* LandingPage.css */

text-med{
  font-size: 1.5rem !important;
  font-weight: 700;
  line-height: 130%;
}


.text-lightbloo{
  color: #97C5DF;
}

.ctaButton {
    background-color: transparent;
    border: 1px solid#97C5DF;
    color: white;
    padding: 0.244rem 0.8125rem 0.40625rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.125rem;
    border-radius: .40rem;
    line-height: 130%;
    // margin-top: 58px !important;
   
    margin: 4px 2px;
    cursor: pointer;
    transition: 0.3s;
  }
  @media (min-width: 1280px) {
    .ctaButton {
      margin-top: 58px !important;
      padding: 0.1875rem 0.625rem 0.3125rem;
      
    }
.play-pause-button{

    padding-top: 0.625rem;
    padding-bottom: 0.75rem;
    height: 3rem;
    font-size: large;

      
   
    
}



.lower-z{

  z-index: 3000 !important;
}
.play-pause-button:hover{
    background-color: #97C5DF;
    color:#01183a;
}
.ctaButton:hover {
    background-color: #97C5DF;
    color: #01183a;
  }

  }
  
  .ctaButton:hover {
    background-color: #97C5DF;
    color: black;
  }

  @media (min-width: 1280px) {
    .f-display-2 {
      font-size: 4.5625rem !important;
      line-height: 100% !important;
    }
    
  }

  @media (min-width: 744px) and (max-width: 1280px) {
    .f-display-2 {
      font-size: 3.4375rem !important;
      line-height: 100% !important;
    }
    .play-pause-button{
        right: 18px;
        bottom: 20px !important;
    }
    .ctaText::selection {
        background-color: #97C5DF !important;
        color: black !important;
      }
    
  }

//   @media (max-width: 740px) {
//     .f-display-2 {
//       font-size: 2.625rem;
//     }
//   }
  
@media (max-width: 1280px) {
    .ctaContainer{
        bottom: 26px;
        padding-left: 10px;
       
    }
    // .ctaButton{
    //     margin-top: 48px;
    // }
}
  
  @media (max-width: 744px) {
    .f-display-2 {
        font-size: 2.625rem;
      line-height: 110%;
      letter-spacing: .005em;
    }
    .about-text {
      font-size: 1.2rem !important;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: 0;
    
  
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      overflow-wrap: break-word;
    }
    .ctaButton{
       // margin-top: 48px;
    }
    .ctaContainer{
        bottom: 18px;
    }
    .play-pause-button{
        right: 18px;
        bottom: 18px;
    }
    .ctaText::selection {
        background-color: #97C5DF!important;
        color: black !important;
      }
  }
  
  .f-display-2 {
    // font-size: 2.625rem;
   
    // letter-spacing: -.01em ;
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
  }
  


  .fluid-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (change as needed) */
    overflow: hidden;
  }

// .video-container {
//   position: relative;
//   width: 100%;
//   height: 100vh; /* Set height to full viewport height */
//   overflow: hidden;
// }

.video-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  max-height: 100vh;
}

// .video-container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     overflow: hidden;
//     width: 100vw !important;
    
//     height: 100svh;
//     position: relative;
// }

// .fullscreen-video {
//     position: absolute;
//     min-width: 100%;
//     min-height: 100%;
//     width: auto;
//     height: auto;
//     object-fit: cover;
// }

.video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100vw;
    height: 100svh;
    background-color: #000;
  }
  
  .fullscreen-video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    opacity: 50%;
    object-fit: cover;
    filter: brightness(0.7); /* Adjust the brightness value as needed */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #01183a94; /* Adjust the opacity as needed */
  }
  

.flex-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1em;
    background-color: #000000;
    color: white;
  }

  .zInd{
    z-index: 9997;
  }

  .ctaText {
    color: white;
    margin: 0 ;
    font-weight: 449;
    text-align: "left"; // changed from center to left
  }


  .ctaText::selection {
    background-color: #97C5DF !important;
    color: black !important;
  }

//   .ctaButton {
//     background-color: transparent;
//     border: 1px solid white;
//     color: white;
//     padding: 0.1875rem 0.625rem 0.3125rem;
//     text-align: center;
//     text-decoration: none;
//     display: inline-block;
//     font-size: 1.125rem;
//     line-height: 130%;
//     margin-top: 1.5rem !important;
//     margin-bottom: .75rem !important;
//     margin: 4px 2px;
//     cursor: pointer;
//     transition: 0.3s;
//   }


// .header-bg {
//   position: fixed;
//   width: 100%;
//   height: 100px;
//   z-index: 9999;
//   background: transparent;
//   transition: background 0.5s ease;
// }

// .header-bg.scrolled {
//   background:#01183aed;
//   z-index: 9999;
// }



.play-pause-button {
    position: absolute;
    z-index: 9998;
    padding-top: 0.625rem;
    padding-bottom: 0.75rem;
    height: 2.75rem;
    background-color: #000;
    -webkit-tap-highlight-color: transparent !important;
  
    /* Additional styles here */
  }

 
  .ctaContainer {
    flex-grow: 1;
    position: absolute;
   
    left:0;
    // padding-left: 16px;
    width: 100%;
    // transform: translateX(-100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: 1280px) {
    .ctaContainer {
      transform: translateX(-50%);
      width: 64%;
      font-weight: 449px;
      left: 50%;
      bottom: 28px;
      letter-spacing: -.01em;
    //   padding-left: 10px;
    }
    .play-pause-button{
        right: 28px;
        bottom: 28px;
    }

    
  }
//   @media (min-width: 744px) and (max-width: 1280) {
//     .ctaText {
//      font-size: 3.4375rem !important;
//     }
//   }

//   @media (min-width: ) {
//     .ctaContainer {
//       transform: translateX(-50%);
//       width: 64.4%;
//       left: 50%;
//       bottom: 28px;
//     }
//   }
  
  
  .flex-item {
    flex-basis: calc(33.33% - 1em);
    margin: 0.5em;
    padding: 1em;
    text-align: center;
    background-color: #000000af;
    color: #97C5DF;
    max-width: 100vw;
  }
  
  @media (max-width: 402px) { 
    .flex-item {
    //   flex-basis: calc(50% - 1em) !important;
      min-width: 100% !important;
    }
    // .small-logo{

    // }
  }
.prevent-horizontal-scroll {
    overflow-x: hidden !important;
  }
  
  .fit-cnt{
    width: fit-content !important;
  }

  .full-width-container {
    width: 100vw;
    max-width: 100%;
  }

  .w-100{width: 100vw !important;}

.dark-bg{
  background-color: #01183a;
}

.no-opacity{
  opacity: 1 !important;
}
  .about-text {
    font-size: 1.45rem;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-wrap: break-word;
  }
  

.fullscreen-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    object-fit: cover; /* This will ensure the video aspect ratio is maintained */
}


  
  
  .fluid-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

  }

  .logo-container {
    // position: fixed;
    // top: 23px;
    // left: 24px;
    // max-width: 90%;
    z-index: 9999; /* Optional, this will make sure the logo is always on top of other elements */
  }


.logo {
    width: 275px; /* You can set your desired width here */
    height: auto; /* This will maintain the aspect ratio of your image */
    max-width: calc(100% - 24px);
  }