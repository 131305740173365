.footer-container {
    display: flex;  
    justify-content: space-between; 
    align-items: center;  
    color: #97C5DF;
    flex-wrap: wrap;  // To ensure the content wraps if there's not enough space
}

.icons-social {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; 
}

.copy-header {
    font-size: medium;
}
