
.faq-container{
    color: #97C5DF;
}

.faqs-container{
    color: #97C5DF;
    //border-bottom: 1px solid #97C5DF;
}
    

.question :hover {
    color: #bde7ffea;
    text-decoration: underline !important;
}


  

  .faq-container .mb-2:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5); // White glow around the focused question
  }
  