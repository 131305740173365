.App {
}

#juiceComp {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#time,
.marketLabel {
  color: #919fae;
}

.price {
  color: #1655d0;
  text-align: center;
}

#timeDiv {
  background-color: var(--body_background);
  width: fit-content;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 2px;
}

#profit {
  margin: 2%;
  margin-top: 2%;
  position: absolute;
  border-radius: 8px 8px 8px 8px;

  /* bottom: -30%; */
}

#prev_btn {
  min-width: fit-content;
  width: 30%;
  float: left;
  max-height: fit-content;
  margin: 0;
}
#next_btn {
  /* width: 100%; */
  margin: 0%;
  float: right;
  min-width: fit-content;
  width: 30%;
  max-height: fit-content;
}

#page_num {
  font-size: small;
  margin-left: 20px;
}

#first_btn {
  /* width: 100%; */
  margin: 0%;
  float: right;
  min-width: fit-content;
  width: 30%;
  max-height: fit-content;
}
.pag_buttons {
  min-width: fit-content;
}

@font-face {
  font-family: "Retro";
  src: url("fonts/Retro\ Gaming.ttf") format("truetype");
}

@font-face {
  font-family: "Card";
  src: url("./fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "lightCard";
  src: url("./fonts/CooperHewitt-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SureFooter";
  src: url("./fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "teamFont";
  src: url("./fonts/CooperHewitt-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "leagueHeader";
  src: url("./fonts/CooperHewitt-HeavyItalic.ttf") format("truetype");
}

@font-face {
  font-family: "guide";
  src: url("./fonts/CooperHewitt-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "College";
  src: url("fonts/collegiateflf/CollegiateFLF.ttf") format("truetype");
}

@font-face {
  font-family: "CardHeader";
  src: url("fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Regular.ttf")
    format("truetype");
}

img {
  width: 100%;
  height: 100%;
}

#error {
  color: red;
  font-weight: bold;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#cardDiv {
  /* box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: fit-content; */
}

#cardcomponent {
  display: inline-flex;
}

#card {
  margin: 5px;
  display: inline-flex;
  flex-direction: row;

  font-size: 12px;
  letter-spacing: 0.5px;
  transition: 0.3s;
  font-family: "Card";
  font-weight: 600;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  min-width: 420px;
  width: fit-content;
  color: var(--font_color1);
  /* min-width: 200px; */
  min-height: 100%;

  background-color: var(--card_background2);
}

#card_header {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0; */
  /* transition: 0.3s;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

#marketLabels {
  display: inline-block;
}

#sb {
  padding-top: 50px;
}

#constantCells {
  padding-top: 5px;
}

.star-icon {
  width: 40px !important;
  height: 40px !important;
}

.marketLabel {
}

.pag_buttons :hover {
}

#pagination {
  /* border: 1px solid gray;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%), 0 2px 1px -1px rgb(0 0 0 / 8%), 0 1px 1px 0 rgb(0 0 0 / 10.5%);
  border-radius: 3px; */
  /* background-color :rgb(231,232,232); */
  font-family: "cardHeader";
  width: 100%;
  left: 0;

  height: fit-content;
}

/* #home_spread{
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 50px;


} */

#tickets {
  display: flex;
  flex-direction: row;
}
/* .form {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
} */

@media screen and (max-width: 800px) {
}

/* @media screen and (max-width: 450px) {
  .column {
    min-width: 100px;
  }
} */

/* .cardHeader{
 min-
} */

form {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}

label {
  margin-top: 1rem;
}

input[type="number"] {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  display: block;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}

.btn.btn-dark {
  font-family: "teamFont";
  letter-spacing: 0.65px;
  padding: 10px;
}

.btn.btn-dark:hover {
  transform: none;
  transition: none;
}

.font-med-bloo {
  font-size: medium !important;
}

.font-lg-bloo {
  font-size: large !important;
}

/* .button {
  display: block;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
} */

button:hover {
  transform: translateY(-0.6px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: rgba(10, 67, 107, 0.942);
  color: lightskyblue;
}

.league_header {
  display: flex;
}

#calcForm {
  text-align: center;
}

.signs {
  padding: 5px;
}

#scores {
  margin-left: 10px;
  margin-top: 45px;
  text-align: left;
}

#big-button {
  background-color: #333;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid rgb(0, 4, 101);
  border-radius: 5px;
  display: inline-block;
  padding: 0.75em 1.5em;
  font-size: medium;
  margin-top: 20px;
}

#small-btn {
  font-size: medium;
  padding: 0%;
  font-family: monospace;
  color: #61dafb;
  bottom: 0;
  right: 0;
}

#result {
  margin-top: 25px;
}

#result-text {
  text-align: center;
}

.sure-bets-container {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.sure-bets-header {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.sure-bets-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 40px;

  align-items: center;
}

.sure-bet {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f2f2f2;
}

.sure-bet-name {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.sure-bet-teams {
  float: left;
}

.account-settings-card {
  border: 1px solid #94c2dc77 !important;
  border-radius: 4px;
  box-shadow: 0 0.25px 0.75px 0 rgba(145, 134, 134, 0.3),
    0 0.5px 0.25px -0.25px rgba(170, 155, 155, 0.18);
}

.system-default-font {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.sure-bet-odds {
  font-size: 1.25rem;
}

#juice-num {
  font-size: 40px;
  text-shadow: 1px 1px 2px #333, 2px 2px 4px #ccc;
  transition: all 0.3s ease-in-out;
  transform: scale(1.5) rotate(360deg);
  margin-left: 40px;
}

.juice-num {
  font-size: x-small;
  margin-left: 10px;
  text-shadow: 0.2px 0.2px 0.4px #333, 0.4px 0.4px.8px #ccc;
  /* text-shadow: 1px 1px 2px #333, 2px 2px 4px #ccc; */
  transition: all 0.3s ease-in-out;
  transform: scale(1.5) rotate(360deg);
}

/* #ticket-header-div{

  width: fit-content;
  text-align: center;
} */

#time,
.marketLabel {
  font-family: "CardHeader";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.2px;
  color: #2b4564;
}

#juice-num:hover {
  transform: scale(1.5) rotate(360deg);
}

#juice-info {
  display: flex;
  justify-content: center;
}

#emoji {
  font-size: larger;
}

#dropdown-basic.dropdown-toggle::after {
  display: none;
}

#dropdown-basic.dropdown-toggle::before {
  display: none;
}

#dropdown-basic.dropdown-toggle.show::before {
  display: none;
}

#subHeader {
  font-weight: small;
  font-size: 16px;
  /* padding-top: 200px; */
  word-spacing: 1.2px;
  text-align: center;
  font-family: "guide";
  text-shadow: 2px 2px 2px #333;
  color: whitesmoke;
  /* width: 22vw; */
  /* margin: 20px; */
  /* min-width: 250px; */
}

.margin-navbar {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.logo {
  width: 100px;
  height: 100px;
  text-align: left;
}

.formBtns {
  text-align: "center";
}

#score_col {
  width: 3.5vh;
  min-width: 7%;
}

#team_col {
  font-family: "teamFont";
}
/* 
#eventCol{
  width: fit-content;
  white-space: nowrap;
} */

.teamLogo {
  width: 30px;
  height: 30px;
}

.column {
  width: 5.3vh;

  max-width: fit-content;
  margin: 1vw;
  text-align: center;
  /* padding: .75vw; */
}
.picColumn {
}

#away_spread {
  text-align: center;
}

.row {
  padding-top: 1.5vh;
}
.ticket_row {
  /* padding-top: .25vh ; */
  padding: 0.9vh;
  box-sizing: border-box;
  max-height: fit-content;
  max-width: fit-content;
  min-width: 61px;
  border: 1px solid var(--border_color1);
  border-radius: 2px;
  color: var(--font_color2);
  box-shadow: var(--ticket_shadow);
  background-color: var(--body_background);
}

.cardEffect {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 2px 1px -1px rgb(0 0 0 / 12%),
    0 1px 1px 0 rgb(0 0 0 / 14%);
  border: 1px solid #ccc;
  background-color: white;
}

.cardPriceFont {
  font-family: "Card";
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #1550c5;
}

#homeItem {
  margin-top: 6px;
  min-height: 30px;
}

#homeScore {
  margin-top: 3.25vh;
}
#homeLogo {
  margin-top: 15px;
  min-height: 30px;
}

.ticket-header {
  white-space: nowrap;
  color: var(--font_color4);
}

#logo_col {
  width: 2.5vh;
}

#cardHeader {
  font-weight: 900;
  letter-spacing: 0.2px;
  text-shadow: 1px 1px 0 rgb(0 0 0 / 20%);
  word-break: break-word;
  padding-right: 16px;
}

#headerTitle {
  position: relative;
  top: 25px;
  left: 21.3px;
}

.pag_buttons {
  background: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
  background-size: 200% auto;
  color: white;
  border-radius: 4px;
  margin-top: 5px;
  font-size: 14px;
  display: flex;
  width: 50%;
  transition: all 0.5s ease;
}

.pag_buttons:hover {
  background-position: right center;
}

#away_ml {
}
/* @media (max-width: 600px) {
  #header {
    height: auto;
  }
} */

.teamImgDiv {
  padding-top: 35px;
}

#subHeaderBox {
}

.teamTitleDiv {
  margin-top: 4.5vh;
}

.width100 {
  width: 150%;
}

#price-container {
  text-align: center;
  display: flex;
  max-height: fit-content;
}

#rectangle-footer {
  width: 100%;
  font-size: 14px;

  font-family: "SureFooter";
  position: relative;
  color: white;
  background-image: linear-gradient(140deg, #144a95, #2767a2);
  border-radius: 0 8px 8px 8px;
}
/* #price-container-away {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.team_container {
  margin: 15px;
}
.sure_bookmaker_img {
  max-width: 100%;
  min-width: 55px;
  min-height: 40px; /* best to keep in sync width height of bookmaker img but ehh */
  border-radius: 4px;
  border: 1px solid gray;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%), 0 2px 1px -1px rgb(0 0 0 / 8%),
    0 1px 1px 0 rgb(0 0 0 / 10.5%);
}

#equal_icon {
  margin-left: 20px;
}

.oddBox {
  border: 1px solid lightgrey;
  margin: auto;
  min-height: 42px;
  min-width: 160px;
  max-width: 160px;
  border-radius: 4px;
  max-height: fit-content;
}
.stakeReturnBox {
  min-width: 100px;
  background-color: white;
  color: #195171;
}

.marAuto {
  margin: auto;
}

.minHeight {
  min-height: 1800px;
}

.minWidth {
  min-width: 1500px;
}

body {
  /* min-width: 1500px; */
}

.cardBackground {
  background-color: var(--card_background);
}

.custom-width {
  max-width: 800px; /* Custom width between medium and large */
  margin: 0 auto; /* Center the div horizontally */
}

.custom-toggle-button {
  /* background-color: var(--dropdown_color) !important; */
  border-color: var(--dropdown_color) !important;
  /* background-image: linear-gradient(140deg, #144a95, #2767a2) !important; */
  background-image: var(--nav_background) !important;
}
.custom-dropdown-button {
  padding: 0.05rem 0.05rem !important;
  font-size: 0.8rem !important;
  line-height: 1.5 !important;
  border-top-right-radius: 0.5px !important;
  border-bottom-right-radius: 0.5px !important;
}

.modal_border_radius {
  border-radius: 0.375rem !important;
}

/* .modal{
  background-color: white !important;
} */

#bookie-dropdown {
  background-color: #59646c;
  margin: 0;
}

#sure_team_logo {
  margin-right: 10px;
}
#price {
  min-width: 100%;

  flex: 1;
}

#date-label {
  float: right;
  padding: 3px;
  font-family: "CardHeader";
  font-size: 12.5px;
  font-weight: 500;
  letter-spacing: 1.2px;
  margin-top: 2%;
  color: var(--font_color3);
  margin-right: 4px;
  box-sizing: border-box;
  border: 1.5px solid #ccc;
  border-radius: 6px;
}

@media (min-width: 768px) {
  /* Medium screens and above */
  .main-padding {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}

@media (max-width: 630px) {
  /* Medium screens and above */
  .main-padding {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

@media (max-width: 768px) {
  /* Medium screens and above */
  .main-padding {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

.bg-powder-blue {
  color: whitesmoke;
  font-weight: 600;
}

.bg-powder-bloo {
  background-color: #94c2dc !important;
}

.border-powder-bloo {
  border-color: #94c2dc !important;
}

.custom-text-xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

@media (max-width: 1024px) {
  .custom-text-xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
}

@media (max-width: 640px) {
  .custom-text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
}

.custom-text-very-large {
  font-size: 3.75rem !important;
  line-height: 1rem !important;
}

@media (max-width: 1024px) {
  .custom-text-very-large {
    font-size: 2.5rem !important;
    line-height: 0.8rem !important;
  }
}

@media (max-width: 640px) {
  .custom-text-very-large {
    font-size: 2rem !important;
    line-height: 0.6rem !important;
  }
}

.icon-med {
  height: 40px;
  width: 40px;
}

.transparent-background {
  background-color: rgba(0, 0, 0, 0); /* black with 50% transparency */
}

.text-powder-bloo {
  color: #94c2dc !important;
}

.text-grayish {
  color: #d3efffe8 !important;
}

.text-powder-bloo-two {
  color: #dcf2ffcd !important;
}

.icon-sm {
  height: 30px;
  width: 30px;
}

.icon-large {
  height: 50px;
  width: 50px;
}

.blooify {
  filter: hue-rotate(195deg) brightness(150%) saturate(300%);
}

.icon {
  padding: 2px;
  margin: 5px;
}

#isIntersectionFlag {
  margin-bottom: 15px;
  margin-left: 20px;
}

#burglarize_btn {
  float: right;
  border-radius: 0 0 8px 0;
  height: 100%;
  padding: 5px;
  width: 40%;
}

#calcBtnText {
  margin-top: 6%;
  font-weight: bold;
  font-size: 16px;
  margin-left: 0.5vw;
  letter-spacing: 1px;
  text-transform: uppercase;
}

#calcIcon {
  margin: 0px;
  padding: 0px;
  float: right;
}

#calcBtnText {
  float: left;
  vertical-align: middle;
}

.card_header_field {
  margin-left: 20px;
}

.roundedTop {
  border: 0.05px solid var(--border_color1);
  border-radius: 8px 8px 0 0;
}

a.page-link {
  color: var(--nav_text);
  background-image: var(--nav_background);
  border: #144a95 1px;
}

a.page-link:hover {
  color: cyan;
  background-image: var(--nav_background);
}

.pagination .page-item.active .page-link {
  background-color: rgb(35, 63, 75);
  color: white;
  border: 0.1px solid lightblue;
}

#gray-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* background-color: lightgray; */
  min-width: 495px;

  max-width: 60%;
  font-family: "Card";
  max-height: fit-content;

  margin: 20px;
  /* display: inline-flex; */
  flex-direction: row;
  color: var(--font_color1);

  font-size: 12px;
  letter-spacing: 0.5px;
  transition: 0.3s;
  font-family: "Card";
  font-weight: 600;
  box-sizing: border-box;
  border: 0.05px solid var(--border_color1);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  width: 600px;
  /* min-width: 200px; */
  min-height: 100%;

  background-color: var(--card_background2);
}

.loadingTextLook {
  margin-top: 32px !important;
  font-size: 20px;
}

#teamNames {
  font-family: "teamFont";
  font-size: 14px;
  margin-top: 3%;
  height: fit-content;
}
.teamNames {
  margin: 10px;
}

html,
body {
  height: 100%;
  width: 100%;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

.mainBackground {
  background-color: var(--body_background);
}

.modal-content {
  margin-top: 72px !important;
  background-color: var(--body_background) !important;
  color: var(--modal_text_color) !important;
}

#rectangle-header {
  background-color: var(--card_background);
  color: var(--font_color1);
  font-family: "lightCard";
  font-size: 15px;
  font-weight: lighter;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
}

.profile-component-header {
  background-color: var(--card_background);
  color: var(--font_color1);
  font-size: 15px;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-weight: lighter;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
}

.account-settings-tabs {
  padding: 8px 12px;
  padding-right: 258px;
}

.overview-form-input {
  border-radius: 0.375rem;
  border-width: 0.5px;
  border-color: #9ea7ae;
  width: 40%;
  height: 40px;
}

.selected-tab {
  color: #1f5493;
}

.account-settings-tabs:hover {
  background-color: var(--hover_item);
}

.profile-component-header-span {
  margin-left: 32px;
}

.account-settings-banner {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

#text-element {
  color: black;
  margin-right: 10px;
}

#time {
  white-space: nowrap;
  color: var(--font_color5);
}

.leagueHeader > #pagination {
  position: absolute;
  bottom: 0;
}

.dropdown-item.active {
  background-color: var(--dropdown_active_color) !important;
  /* background-image: var(--dropdown_active_item); */
}

.dropdown-item:hover {
  background-color: #93c2dd !important;
  color: #01183ae0 !important;
}

.max-h-150 {
  max-height: 150px !important;
}

.custom-dropdown-menu {
  background-color: #01183ae0 !important;
  color: #93c2dd !important;
  border: 0.1rem solid #93c2dd !important;
}

.custom-dropdown-item {
  color: var(--font_color4) !important;
  text-transform: uppercase;
}

.loadingText {
  color: var(--font_color4);
  font-size: 20px;
  /* position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0,0,0,.2));
  font-size: 60px; */

  text-shadow: 3px 3px 0 var(--loadiing-color-primary),
    6px 6px 0 var(--loadiing-color-primary),
    9px 9px var(--loadiing-color-primary),
    12px 12px 0 var(--loadiing-color-primary);
  font-family: "Retro";
  font-weight: 1800;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  animation: shadows 1.2s ease-in infinite, move 1.2s ease-in infinite;
  letter-spacing: 0.4rem;
}

.loadingTextColor {
  color: var(--font_color4);
}

.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
  font-size: 60px;
}
.waviy span {
  font-family: "Alfa Slab One", cursive;
  position: relative;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(0.1s * var(--i));
}
@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}

.screenContentMinHeight {
  min-height: 600px !important;
}

.dropdownyo {
  z-index: 9999 !important;
  overflow-y: scroll;
}

#leagueHeader {
  position: relative;
}
/* 
.dropstart .dropdown-menu[data-bs-popper]{
  z-index: 1000 !important;
} */

#nav_bar {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);

  border-radius: 1px;
  z-index: 1000 !important;
  overflow: visible;
  height: 74px;
  background-image: linear-gradient(140deg, #144a95, #2e6191);
  padding: 20px;
  opacity: 94%;
  font-family: "Retro";
  /* background-image: url("images/Sportsbook-735x371.jpeg"); */
  background-blend-mode: multiply;

  background-repeat: no-repeat;
  text-shadow: 2px 2px 2px #333;
  box-shadow: 0px 6px 8px -6px rgba(0, 0, 0, 0.5);

  position: relative;
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  /* animation: slide-in-left 1s ease-in-out; */
}

.profile-icon-dropdown {
  z-index: 1000 !important;
}

.slide-animation {
  animation: slide 2s ease-in-out infinite;
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.text_shadows {
  text-shadow: 3px 3px 0 var(--loadiing-color-primary),
    6px 6px 0 var(--loadiing-color-primary),
    9px 9px var(--loadiing-color-primary),
    12px 12px 0 var(--loadiing-color-primary);
  font-family: "Retro";
  font-weight: 400;
  text-transform: uppercase;
  font-size: calc(2rem + 5vw);
  text-align: center;
  margin: 0;
  color: var(--loadiing-color-primary);
  /* //color: transparent;
  //background-color: white;
  //background-clip: text; */
  animation: shadows 1.2s ease-in infinite, move 1.2s ease-in infinite;
  letter-spacing: 0.4rem;
}

@keyframes shadows {
  0% {
    text-shadow: none;
  }
  10% {
    text-shadow: 1px 1px 0 var(--loadiing-color-secondary);
  }
  20% {
    text-shadow: 1px 1px 0 var(--loadiing-color-secondary),
      2px 2px 0 var(--loadiing-color-tertiary);
  }
  30% {
    text-shadow: 1px 1px 0 var(--loadiing-color-secondary),
      2px 2px 0 var(--loadiing-color-tertiary),
      3px 3px var(--loadiing-color-quaternary);
  }
  40% {
    text-shadow: 1px 1px 0 var(--loadiing-color-secondary),
      2px 2px 0 var(--loadiing-color-tertiary),
      3px 3px var(--loadiing-color-quaternary),
      4px 4px 0 var(--loadiing-color-quinary);
  }
  50% {
    text-shadow: 1px 1px 0 var(--loadiing-color-secondary),
      2px 2px 0 var(--loadiing-color-tertiary),
      3px 3px var(--loadiing-color-quaternary),
      4px 4px 0 var(--loadiing-color-quinary);
  }
  60% {
    text-shadow: 1px 1px 0 var(--loadiing-color-secondary),
      2px 2px 0 var(--loadiing-color-tertiary),
      3px 3px var(--loadiing-color-quaternary),
      4px 4px 0 var(--loadiing-color-quinary);
  }
  70% {
    text-shadow: 1px 1px 0 var(--loadiing-color-secondary),
      2px 2px 0 var(--loadiing-color-tertiary),
      3px 3px var(--loadiing-color-quaternary);
  }
  80% {
    text-shadow: 1px 1px 0 var(--loadiing-color-secondary),
      2px 2px 0 var(--loadiing-color-tertiary);
  }
  90% {
    text-shadow: 1px 1px 0 var(--loadiing-color-secondary);
  }
  100% {
    text-shadow: none;
  }
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(-12px, -12px);
  }
  50% {
    transform: translate(-12px, -12px);
  }
  60% {
    transform: translate(-12px, -12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.slide {
  /* transition: all 0.5s cubic-bezier(1, 0, 0, 1); */
  animation: slide-in-fast 3s;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Apply the animation to the navbar */

@keyframes slidein {
  from {
    transform: rotateX(20deg);
  }

  to {
    transform: rotateX(0deg);
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(-140%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes walk-animation {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 -2391px;
  }
}

@keyframes move-animation {
  from {
    margin-left: 120%;
  }

  to {
    margin-left: -120%;
  }
}

.move {
  animation: move-animation 5.5s infinite;
}
.walking {
  animation: walk-animation 2s steps(12) infinite;
  /* background: url("./assets/cat_tiles.png") 0 0 no-repeat; */
}

@keyframes slide-in-fast {
  from {
    transform: translateX(-130%) rotateZ(-5deg);
  }

  30% {
    transform: translateX(-80%) rotateZ(5deg);
  }
  35% {
    transform: translateX(-80%) rotateZ(0deg);
  }

  75% {
    transform: translateX(0%) rotateZ(5deg);
  }

  /* 10% {
    transform:   translateX(-100%)rotateZ(0deg);
  }
  
  30% {
    transform:   translateX(-50%)rotateZ(0deg);
  }
  40% {
    transform:   translateX(-40%)rotateZ(0deg);
  } */

  to {
    transform: translateX(100%) rotateZ(5deg);
  }
}

/* Apply the animation to the navbar */

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Add animation effect to the element */

/* Keyframe animation */

#nav_bar:hover {
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -2px rgba(0, 0, 0, 0.05);
  transform: perspective(1000px) rotateX(0deg);
  opacity: 100%;
}
.nav-links {
  color: lightblue;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_tab {
  color: lightskyblue;
  opacity: 100%;
}

#nav_bar {
  margin-top: -10px;
  margin-bottom: -30px;
}

#nav_tab a:hover {
  color: white;
}
.nav-links a {
  color: #61dafb;
  font-size: 14px;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  font-weight: 600;

  padding: 10px;
  transition: all 0.2s ease-in-out;
}
.nav-links a:hover {
  background-color: lightseagreen;
  border-radius: 5px;
  transform: scale(1.1);
}

.no-pad {
  padding: 0% !important;
}
.navbar-brand {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #61dafb;
}
.navbar-toggler {
  border: none;
}

.blurred {
  filter: blur(2.6px);
}

.no-interaction {
  pointer-events: none;
}

.dark-transparent {
  background: #01183ae8 !important;
}

.dark-trnsparent {
  background: #011d47e8 !important;
}

.dark-bloo-bg {
  background: #01183a !important;
}

.slate-dark-transparent {
  background: #09264fac !important;
}

/* custom.css */
.bg-gradient-main {
  background: repeating-linear-gradient(400deg, #01183a, #09264f94 100%);
}

.ev-green{
  background-color: #35561eed;
}

.ev-green-border{
  border: 2px solid #79c631;
}

.ev-red{
  background-color: #56201eed; 
}

.scalable {
  transition: transform 0.3s ease-in-out;
}

.scalable:hover {
  transform: scale(1.09); 
}

.fit-content {
  height:fit-content !important;
}

.fit-content-width {
  width:fit-content !important;
}

.ev-red-border{
  border: 2px solid #c63131;
}

.no-ev-gray{
  background-color: #1d1d28ed;
}


.no-ev-gray-border{
  border: 2px solid #90a0ae;
}


@media (min-width: 1024px) {
.lg-padding {
    padding: 48px !important; /* Adjust the padding size as needed */
  }
}

@media (max-width: 500px) {
  .sm-pad {
      padding: 12px !important; /* Adjust the padding size as needed */
    }
  }


@media (max-width: 840px) {
  .md-pad {
      padding: 18px !important; /* Adjust the padding size as needed */
    }
  }

/* custom.css */
.bg-radial-gradient {
  background: radial-gradient(circle, #01183a, #09264fac);
}


/* background: linear-gradient(45deg, #01183a, #09264fac); */
.hover-bg-slate-dark {
  transition: background-color 0.3s ease-in-out;
}

.hover-bg-slate-dark:hover {
  background: #09264fac !important;
}

.responsive-content-margin {
  margin: 1rem;
}

@media (min-width: 768px) {
  .responsive-content-margin {
    margin: 2rem;
  }
}

@media (min-width: 768px) {
  .why-us-m {
    margin-top: 96px;
    margin-bottom: 48px !important;
  }
}

@media (min-width: 1024px) {
  .feature-list-m {
    margin-left: 96px;
    margin-right: 96px;
  }
}

@media (min-width: 768px) {
  .headline-m {
    margin-top: 56px;
    margin-bottom: 48px;
  }
}

@media (min-width: 768px) {
  .feature-list-p {
    padding-left: 96px;
    padding-right: 96px;
  }
}

.navbar-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This makes sure your image covers the entire parent and is resized as needed. */
}

.far-right {
  position: absolute;
  right: 16px;
}

.logo-container {
  position: fixed;
  left: 16px;
  max-width: 90%;
  z-index: 9999; /* Optional, this will make sure the logo is always on top of other elements */
}

/* Tablet devices */
@media (min-width: 768px) {
  .logo-container {
    left: 24px;
  }
  .far-right {
    position: absolute;
    right: 24px;
  }
}

/* Desktop devices */
@media (min-width: 1024px) {
  .logo-container {
    left: 32px;
  }
  .far-right {
    position: absolute;
    right: 32px;
  }
}

.header-bg {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 64px;
  z-index: 9999;
  background: transparent;
  transition: background 0.5s ease;
}

.header-bg.scrolled {
  background: #01183aed;
  z-index: 9999;
}

.no-opacity {
  opacity: 1 !important;
}

.light-btm-brder {
  border-bottom: 1px solid #97c5df !important;
}

.extra-light-btm-brder {
  border-bottom: 1px solid #97c5df41 !important;
}

.light-brder {
  border: 0.05px solid #97c5df6a !important;
}

.md-brder {
  border: 0.1px solid #97c5df6a !important;
}

.light-top-brder {
  border-top: 1px solid #97c5df !important;
}

.light-btm-brder-thin {
  border-bottom: 0.1px solid #97c5df98 !important;
}

.marketImg {
  /* width: 80px;   */
  max-width: 53px;
  min-width: 53px;
  height: 53px;
  min-height: 53px;
  border-radius: 4px;
  right: 32px;
  margin: 1.5px;
  border: 1px solid gray;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%), 0 2px 1px -1px rgb(0 0 0 / 8%),
    0 1px 1px 0 rgb(0 0 0 / 10.5%);
}

.betInputs {
  width: 40%;
  display: inline-block;
  margin-left: 20px;
}

#inputDiv {
  /* display: flex; */
}

#team_pic {
  max-width: 40px;
  max-height: 4vh;
  display: inline-block;
  margin-top: 1.25vh;
}

#amountsDiv {
  outline: 1px dashed black;
}
.custom-dropdown-menu {
  z-index: 2000;
}

.dropdown-menu.show {
  /* z-index: 9999; */
}

#home_team_pic {
  /* padding: 2px; */
  /* margin-top: 22px; */
}

.profile-dropdown-menu {
  /* z-index: 2000 !important; */
  position: relative;
}

#header {
  overflow: visible !important;
}

html,
body,
#root {
  width: 100%;
}

@media screen and (max-width: 300px) {
  .column {
    min-width: 16%;
  }
  #subHeader {
    width: 8%;
  }
  .App-header {
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  @media (max-width: 640px) {
    /* Adjust the max-width as needed for mobile screens */
    .modal-custom {
      max-width: 60% !important; /* Adjust as needed */
      max-height: 80%; /* Adjust as needed */
      overflow: auto; /* Allows scrolling if content is larger than the modal */
    }
  }

  /* .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;
    padding: 1rem;
  } */
  .sure-bets-list {
    margin-left: 130px;
  }
}

@media screen and (max-width: 400px) {
  .App-header {
    min-width: 135%;
    width: 130%;
  }
}

@media screen and (max-width: 680px) {
  .column {
    min-width: 16%;
  }
  #subHeader {
    width: 20%;
  }
}

@media screen and (min-width: 680px) and (max-width: 900px) {
  .column {
    min-width: 13%;
  }
  #subHeader {
    width: 50%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .column {
    min-width: 12%;
  }

  #subHeader {
    width: 60%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .column {
    min-width: 11%;
  }

  #subHeader {
    width: 60%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .column {
    max-width: 10%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1950px) {
  .column {
    max-width: 9%;
  }
}

@media screen and (min-width: 1950px) and (max-width: 2300px) {
  .column {
    max-width: 8%;
  }
}

@media screen and (min-width: 2300px) and (max-width: 3000px) {
  .column {
    max-width: 7%;
  }
}

.run-cycle {
  animation-name: run-cycle;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-50%);
}

@keyframes run-cycle {
  0% {
    transform: translateY(0) rotate(0);
  }
  10% {
    transform: translateY(-5%) rotate(-5deg);
  }
  20% {
    transform: translateY(-10%) rotate(-10deg);
  }
  30% {
    transform: translateY(-15%) rotate(-15deg);
  }
  40% {
    transform: translateY(-20%) rotate(-10deg);
  }
  50% {
    transform: translateY(-25%) rotate(-5deg);
  }
  60% {
    transform: translateY(-30%) rotate(0);
  }
  70% {
    transform: translateY(-35%) rotate(5deg);
  }
  80% {
    transform: translateY(-40%) rotate(10deg);
  }
  90% {
    transform: translateY(-45%) rotate(15deg);
  }
  100% {
    transform: translateY(-50%) rotate(10deg);
  }
}

.regFont {
  font-size: 16px;
  word-spacing: 1.2px;
  font-family: "guide";
  text-shadow: 0.1px 0.1px 0.1px rgb(71, 70, 70);
}

.align-items-flex-start {
  align-items: flex-start;
}

.text-light-bloo {
  color: #d3e8f5;
}

.hover-effect:hover {
  transform: scale(1.1);
}

.height-auto {
  height: auto !important;
}

.post-text-light {
  text-align: left !important;
}

.guide-font {
  word-spacing: 1.2px;
  font-family: "guide";
  text-shadow: 0.1px 0.1px 0.1px rgb(71, 70, 70);
}

.cardBackground2 {
  background-color: var(--card_background2);
}

.mb-6 {
  margin-bottom: 4rem;
}

.retro {
  font-family: "Retro";
  text-shadow: 0.5px 0.5px 0.5px #333;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

/* body{
  background-color: var(--body_background);
} */

.navigationBar {
  /* background-color: #1c2535; */
  /* min-height: 10vh; */
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 233, 254);
  font-family: "Retro" !important;
  /* background-image: url("images/Sportsbook-735x371.jpeg"); */
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid rgb(26, 26, 26);
  border-radius: 0.5px;
  text-shadow: 2px 2px 2px #333;
  box-shadow: 0px 6px 8px -6px rgba(0, 0, 0, 0.5);
}

.App-header {
  background-color: #1c2535;
  min-height: 10vh;
  /* display: flex;
  flex-direction: column; */
  font-size: calc(10px + 2vmin);
  color: rgb(0, 233, 254);
  font-family: "Retro";
  /* background-image: url("images/Sportsbook-735x371.jpeg"); */
  background-blend-mode: multiply;
  /* background-size: cover; */
  background-repeat: no-repeat;
  border: 2px solid rgb(26, 26, 26);
  border-radius: 0.5px;
  text-shadow: 2px 2px 2px #333;
  box-shadow: 0px 6px 8px -6px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
  margin: 0;
  margin-right: 0;

  /* min-width: 115%; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
