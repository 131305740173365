/* CSS file */
.positive-ev {
    background-color: #35561eed; /* Dark green background with opacity */
    color: #ffffff; /* White text */
    width: 100%;
    padding: 8px 12px; /* Vertical and horizontal padding */
    border: 2px solid #79c631; /* Green border */
    border-radius: .4em; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: inline-flex; /* For inline level flex-container */
    gap: 8px; /* Space between child elements */
    font-weight: bold; /* Bold font */
  }

  .positive-ev-other {
    padding: 8px 12px; /* Vertical and horizontal padding */
    display: inline-flex; /* For inline level flex-container */
    gap: 8px; /* Space between child elements */
    font-weight: bold; /* Bold font */ 
  }
  
  