.unauth-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.55);
  }
  
  .unauth-modal {
    background-color: #b7dcf3 ;
    opacity: 1;
    color: #01183a !important;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    max-width: 90vw;
    width: auto;
    text-align: center;
    transition: transform 0.3s ease;
    transform: scale(1);
  }

//   .unauth-modal-textbox  {
//     background-color: #c1d9e8 ;
//   }
  
  
  .unauth-modal:hover {
    transform: scale(1.02);
  }
  
  /* For tablets */
  @media (min-width: 768px) {
    .unauth-modal {
      max-width: 60vw;
    }
  }
  
  /* For desktops */
  @media (min-width: 992px) {
    .unauth-modal {
      max-width: 50vw;
    }
  }
  