.linear-indeterminate {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #f3f3f3; /* Background color of the progress bar container */
    overflow: hidden;
  }
  
  .progress-bar {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1976d2; /* Color of the moving bar */
    transform: translateX(-100%);
    animation: indeterminate-animation 2s infinite linear;
  }
  
  @keyframes indeterminate-animation {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  