img {
  width: 24px;
  height: 24px;
}

img.upvoted {
  filter: invert(38%) sepia(79%) saturate(2772%) hue-rotate(80deg)
    brightness(94%) contrast(92%);
}

.hover-effect {
  transition: background-color 0.3s, color 0.3s;
}

.custom-modal-content {
  background-color: #01122c !important;
}

.custom-modal-text {
  color: #d3e8f5 !important;
}

html, body, #root {
  height: 100%;
  min-height: 100%;
}

body {
  overflow-y: auto;
}

.vote-icon {
  width: 50px;
  height: 50px;
  transition: transform 0.2s;
}

.delete-icon:active {
  transform: scale(0.8);
}

.delete-icon {
  width: 50px;
  height: 50px;
  transition: transform 0.2s;
}

.delete-icon:active {
  transform: scale(0.8);
}

img.downvoted {
  filter: invert(23%) sepia(53%) saturate(6461%) hue-rotate(359deg)
    brightness(92%) contrast(87%);
}

.addSuggestionTop {
  display: none;

  @media (min-width: 830px) {
    display: block;
  }
}

.addSuggestionBottom {
  display: block;

  @media (min-width: 830px) {
    display: none;
  }
}


img:hover {
  stroke: blue; /* Change to your preferred stroke color */
}

.suggestion-border-bottom {
  border-bottom: 1px solid #d3e8f5; /* Change #ccc to any color you prefer for the border */
}

.suggestion-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: start;
}

.text-lefty {
  text-align: left !important;
}

.icon-container {
  display: flex;
  align-items: center;
  min-width: 7vw;
}

.suggestion-modal-width {
  width: 100% !important;
  max-width: 65vw !important;
  min-width: 280px !important;
}

.tip {
  position: relative;
  display: inline-block;

  cursor: pointer;
}

.tip2 {
  position: relative;
  display: inline-block;

  cursor: pointer;
}

.tip .tooltiptext {
  visibility: hidden;
  width: 65vw;
  min-width: 260px;
  background-color: rgba(3, 12, 41, 0.956);
  color: #97c6de;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: small;
 // overflow-y: auto;
  position: absolute;
  z-index: 1;
  top: 100%; /* Adjust this value to control the position of the tooltip */
  left: 50%;
  margin-left: -200px; /* Use negative margin to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tip2 .tooltiptext {
  visibility: hidden;
  width: 65vw;
  min-width: 260px;
  background-color: rgba(3, 12, 41, 0.956);
  color: #97c6de;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: small;
 // overflow-y: auto;
  position: absolute;
  z-index: 1;
  top: 100%; /* Adjust this value to control the position of the tooltip */
  left: 50%;
  margin-left: 00px; /* Use negative margin to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tip2:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 1000px) {
  img.vote-icon:hover {
    filter: invert(42%) sepia(92%) saturate(2732%) hue-rotate(190deg)
      brightness(90%) contrast(89%);
  }
  img.delete-icon:hover {
    filter: grayscale(100%) brightness(30%) sepia(70%) hue-rotate(-45deg)
      saturate(700%);
  }
}

@media (max-width: 800px) {
  .suggestion-modal-width {
    width: 100% !important;
    max-width: 80vw !important; /* Adjust as needed */
    min-width: 280px !important; /* Adjust as needed */
  }
}

@media (max-width: 599px) {
  .suggestion-modal-width {
    width: 100% !important;
    max-width: 95vw !important; /* Adjust as needed */
    min-width: 280px !important; /* Adjust as needed */
  }
}

@media (min-width: 500px) {
  .tip .tooltiptext {
    width: 180px;
  }

  .tip2 .tooltiptext {
    width: 180px;
  }

}

.text-container {
  margin: 0;
}
